$light_brown: #9e8982;
$dark_brown: #292313;
$blue: #516ea8;
$light_grey: #f6f6f6;
$dark_grey: #f6f6f6;
$yellow: #f5d67c;
$off_white: #ede9e8;
$primary: #c8893e;
// $primary: #34435c;
$grey: #757575;
